@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-display: block;
    font-family: Gilroy-Regular;
    src: url('/fonts/Gilroy-Regular.otf') format('opentype');
  }
  @font-face {
    font-display: block;
    font-family: Gilroy-ExtraBold;
    src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  }
  @font-face {
    font-display: block;
    font-family: Gilroy-Medium;
    src: url('/fonts/Gilroy-Medium.otf') format('opentype');
  }
  @font-face {
    font-display: block;
    font-family: Gilroy-SemiBold;
    src: url('/fonts/Gilroy-SemiBold.otf') format('opentype');
  }
  @font-face {
    font-display: block;
    font-family: Gilroy-Bold;
    src: url('/fonts/Gilroy-Bold.otf') format('opentype');
  }

  body {
    @apply bg-background font-family-regular;
  }

  .no-select-or-highlight {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  @import './alice-carousel.css';
  @import './react-multi-carousel.css';
  @import './react-slick.css';
  @import './react-slick-theme.css';

  .content {
    padding: 0px 20px;
  }
  @screen lg {
    .content {
      padding: 0px 80px;
    }
  }

  .blur {
    opacity: 0.8;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }

  .black-and-white {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  }

  .skeleton-background {
    @apply bg-gray-700 animate-pulse;
  }
  .skeleton-text {
    @apply text-gray-700 animate-pulse;
  }
  .skeleton-border {
    @apply border-gray-700 animate-pulse;
  }

  /* Video */
  #hero-player.jwplayer.jw-flag-aspect-mode {
    width: 100% !important;
    height: 100% !important;
  }
  #hero-player.jwplayer > .jw-wrapper {
    background-color: transparent;
  }

  /* Loading Spinner */
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    /* margin: 8px; */
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* React Slick */
  .hero-carousel-dots {
    position: absolute;
    bottom: 30px;

    display: flex !important;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    padding: 0;
    margin: 0px auto;

    list-style: none;

    text-align: center;
  }
  @screen md {
    .hero-carousel-dots {
      bottom: 50px;
    }
  }
}

html,
body {
  margin: 0;
}

#__next {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
